import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ActivatedRoute,
    DefaultUrlSerializer,
    Router,
    UrlTree,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MaterialCssVarsService } from 'angular-material-css-vars';
import { ApiService } from '../services/api/api.service';
import { AuthService } from '../services/auth/auth.service';
import { defaultPrimaryColor } from '../services/constants';
import { SnackBarService } from '../services/snack-bar/snack-bar.service';
import { SettingsService } from '../services/user/settings.service';
import { CustomValidator } from './../shared/custom.validators';
import { PlatformConfigurationService } from '../services/platform-configuration.service';

@Component({ templateUrl: './login.component.html' })
export class LoginComponent implements OnInit {
    platformConfigurationService = inject(PlatformConfigurationService);

    showForgotPasswordDialog = false;
    form = new FormGroup({
        email: new FormControl('', [
            Validators.required,
            CustomValidator.email,
        ]),
        password: new FormControl('', [Validators.required]),
        rememberMe: new FormControl(true),
    });
    errorMessages = {
        customEmail: () => 'form.email_not_valid',
        customEmailSpaces: () => 'form.email_not_valid_spaces',
        customEmailSpacesDavid: () => 'Remove the spaces, David!',
    };

    get email() {
        return this.form.get('email');
    }

    get password() {
        return this.form.get('password');
    }

    constructor(
        private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private materialCssVarsService: MaterialCssVarsService,
        private settings: SettingsService,
        private snackBar: SnackBarService,
        private api: ApiService,
    ) {}

    async ngOnInit() {
        await this.playVideo();
        this.settings.clear();
        this.materialCssVarsService.setPrimaryColor(defaultPrimaryColor);
        this.materialCssVarsService.setDarkTheme(false);
        localStorage.clear();
    }

    async playVideo() {
        const video = document.getElementById(
            'background-video',
        ) as HTMLVideoElement;
        // For some reason we have to specify this here, instead of in the HTML
        video.muted = true;
        await video.play();
    }

    async login() {
        try {
            await this.auth
                .login(
                    this.email.value,
                    this.password.value,
                    this.form.get('rememberMe').value,
                )
                .toPromise();
            const goTo = this.route.snapshot.queryParams['goTo'] ?? '';
            const urlSerializer = new DefaultUrlSerializer();
            const urlTree: UrlTree = urlSerializer.parse(goTo);
            this.router.navigateByUrl(urlTree);
        } catch (error) {
            if (error.error.message) {
                alert(error.error.message);
            } else {
                const title = await this.translate
                    .get('login.invalid_credentials')
                    .toPromise();
                alert(title);
            }
        }
    }

    submit() {
        this.email.markAsTouched();
        this.password.markAsTouched();
        if (this.form.valid) {
            this.login();
        }
    }

    async sendResetEmail() {
        const result = await this.api.users.resetPassword(
            this.form.get('email').value,
        );
        if (result) {
            this.snackBar.open('forgot_password.email_sent');
            this.showForgotPasswordDialog = false;
        }
    }
}
